import PropTypes from 'prop-types';
import LoopingTextBanner from 'components/content-components/LoopingTextBanner';

const LoopingTextBannerDataTransformerBlock = ({ content = {} }) => {
    const { anchorTag = [], label = '' } = content;

    return <LoopingTextBanner id={anchorTag?.[0]?.anchorTag} text={label} />;
};

LoopingTextBannerDataTransformerBlock.propTypes = {
    content: PropTypes.object.isRequired,
};

export default LoopingTextBannerDataTransformerBlock;
