import PropTypes from 'prop-types';
import Marquee from '@activebrands/core-web/components/Marquee';
import { styled } from '@activebrands/core-web/libs/styletron';
import Paragraph from 'components/text/Paragraph';

const Message = styled('div', {
    display: 'flex',
    alignItems: 'center',
    margin: '0 48px',
    whiteSpace: 'nowrap',
});

const LoopingTextBanner = ({ id = '', text = '' }) => {
    if (!text.length) {
        return null;
    }

    return (
        <Marquee pauseOnHover id={id} speed={8}>
            <Message>
                <Paragraph fontKeys={['Primary/40_100_-2', null, 'Primary/72_100_-3', null, 'Primary/96_100_-3']}>
                    {text}
                </Paragraph>
            </Message>
        </Marquee>
    );
};

LoopingTextBanner.propTypes = {
    id: PropTypes.string,
    text: PropTypes.string,
};

export default LoopingTextBanner;
